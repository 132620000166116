.secondary-text {
  color: #01427e;
}

.default-text {
  color: #7a7a7a;
}

/* fonts */
@font-face {
  font-family: "quatRegular";
  src: url("../font/Quattrocento/Quattrocento-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "quatBold";
  src: url("../font/Quattrocento/Quattrocento-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

.font-quatRegular {
  font-family: quatRegular;
}

.font-quatBold {
  font-family: quatBold;
}

.hover-text {
  color: #7a7a7a;
  cursor: pointer;
  /* line-height: 55px; */
}

.hover-text:hover {
  color: #01427e;
  cursor: pointer;
  /* line-height: 55px; */
}

.hover-text-bold {
  color: #7a7a7a;
  /* cursor: pointer; */
  /* line-height: 55px; */
}

.hover-text-bold:hover {
  color: #7a7a7a;
  /* font-weight: 700; */
  /* cursor: pointer; */
  /* line-height: 55px; */
}

.primary-bg {
  background-color: #01427e;
  opacity: 0.9;
  color: white;
}

.default-bg {
  background-color: #7f8081;
  color: white;
}

.display-center {
  display: flex;
  align-items: center;
}

.rounded-img {
  border-radius: 50%;
  width: 220px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.nav-border {
  border-bottom: 1px solid rgba(1, 1, 1, 0.1);
}

.text-justify {
  text-align: justify;
  text-align-last: left;
}

.grey-text {
  color: #646464 !important;
}

.light-grey-text {
  color: #b7b9bb;
}
