@media screen and (min-width: 601px) {
  .nav-logo {
    height: 55px;
    z-index: 9;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 600px) {
  .nav-logo {
    height: 34px;
    z-index: 9;
  }
}

.navbar {
  font-weight: 500;
  box-shadow: none !important;
}

@media (max-width: 991px) {
  #navbarCollapse {
    background-color: #fff;
    opacity: 0;
    margin-top: 0px !important;
    min-height: 100vh;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 90px;
    transition: 0.3s;
  }
  .fade-in {
    opacity: 0.9 !important;
    transition: 0.3s !important;
  }
}

#nav-icon1 {
  z-index: 99;
}

/* ------------------------------ */
/* mdb mobile nav style -- start  */

$icon-height: 20px;
$icon-width: 30px;
$line-height: 3px;

$icon-top: 0;
$icon-middle: ($icon-height / 2) - ($line-height / 2);
$icon-bottom: $icon-height - $line-height;

$flip-duration: 0.5s;

@media screen and (min-width: 992px) {
  .hide-mobile-nav {
    display: none !important;
  }
}

.mdb-hamburger-menu-container {
  z-index: 1500;
  // margin-right: 15px;
}

#mdb-hamburger-menu {
  position: relative;
  height: $icon-height;
  width: $icon-width;
  margin: 30% auto;
  display: block;
}

.hamburger-line {
  position: absolute;
  height: $line-height;
  width: $icon-width;
  background-color: #01427e;
  transform-origin: 50%;
}

.top {
  top: $icon-top;
}

.middle {
  top: $icon-middle;
}

.bottom {
  top: $icon-bottom;
}

.opened {
  .top {
    top: $icon-middle;
    transform: rotate(-225deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
  }
  .middle {
    opacity: 0;
    transition: opacity $flip-duration;
  }
  .bottom {
    top: $icon-middle;
    transform: rotate(225deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
  }
}

.closed {
  .top {
    top: $icon-top;
    transform: rotate(0deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
  }
  .middle {
    opacity: 1;
    transition: opacity $flip-duration;
  }
  .bottom {
    top: $icon-height - $line-height;
    transform: rotate(0deg);
    transition-property: transform, top;
    transition-duration: $flip-duration;
  }
}

/* mdb mobile nav style -- end  */
/* ---------------------------- */

/* ------------------------------ */
/* mobile nav one style -- start  */

.mn1 {
  width: 0%;
  height: 100%;
  background: #01427e;
  position: fixed;
  z-index: 1499;
  top: 0;
  right: 0;
  overflow: hidden;
  transition: width 0.1s ease-in-out;
  -webkit-box-shadow: 4px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 0px 25px 0px rgba(0, 0, 0, 0.75);
}

.mn1.clicked {
  width: 300px;
}

.mn1 > ul {
  margin-top: 88px;
  padding: 0;
  list-style: none;
}

.mn1 > ul > li > a {
  box-sizing: border-box;
  padding: 11px 22px;
  width: 100%;
  color: #222327;
  display: block;
  text-decoration: none;
  font-weight: 700;
}

.mn1 > ul > li > a:hover {
  background: rgba(0, 0, 0, 0.2);
}

#mn1-hamburger-button-container {
  margin: 0px 15px 12px 0px;
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: 1500;
}

#mn1-hamburger-button {
  display: block;
  width: 28px;
  height: 20px;
}

#mn1-hamburger-button > span {
  position: absolute;
  border-radius: 20px;
  height: 20%;
  background: #222327;
  top: 0px;
}

#mn1-hamburger-button > span:nth-child(1) {
  width: 100%;
  top: 0%;
  transition: top 0.1s ease-in, width 0.1s ease 0.1s;
}

#mn1-hamburger-button > span:nth-child(2) {
  width: 95%;
  top: 50%;
  transition: all 0.1s ease-in;
  transition-delay: 0.15s;
}

#mn1-hamburger-button > span:nth-child(3) {
  width: 95%;
  top: 50%;
  transition: all 0.1s ease-in;
  transition-delay: 0.15s;
}

#mn1-hamburger-button > span:nth-child(4) {
  width: 70%;
  top: 100%;
  transition: top 0.1s ease-in, width 0.1s ease 0.1s;
}

#mn1-hamburger-button.clicked > span:nth-child(1) {
  top: 50%;
  width: 0%;
}

#mn1-hamburger-button.clicked > span:nth-child(2) {
  top: 50%;
  transform: rotate(-45deg);
}

#mn1-hamburger-button.clicked > span:nth-child(3) {
  top: 50%;
  transform: rotate(45deg);
}

#mn1-hamburger-button.clicked > span:nth-child(4) {
  top: 50%;
  width: 0%;
}

/* mobile nav one style -- end  */
/* ---------------------------- */

/* text and div alignment  */
/* ---------------------------- */

.center {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.react-parallax-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; // center text
  justify-content: flex-start; // left text
  position: relative;
}

/* Text headings */

@media screen and (min-width: 992px) {
  .display-h1 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h2 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h3 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
  }

}

@media screen and (max-width: 991px) {
  .display-h1 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h2 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h3 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
  }

}

@media screen and (max-width: 690px) {
  .display-h1 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h2 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-h3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

}

/* text and div alignment -- end  */
/* ---------------------------- */

/* ------------------------------------------------ */
/* Dark Header Overlay  */
/* ------------------------------------------------ */

.page-header {
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
}

.page-header > .container {
  color: #fff;
}

.page-header .title {
  color: #fff;
  padding-top: 1em;
}

.header-filter::before {
  background: rgba(0, 0, 0, 0.5);
}

.header-filter .container {
  z-index: 2;
  position: relative;
}

.clear-filter::before {
  background: none;
}

.dark-filter:after {
  background: rgba(1,66,126, 0.64);
  background: linear-gradient(
    -45deg,
    rgba(1,66,126, 0.88) 0%,
    rgba(1,66,126, 0.45) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    rgba(1,66,126, 0.88) 0%,
    rgba(1,66,126, 0.45) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(1,66,126, 0.88) 0%,
    rgba(1,66,126, 0.45) 100%
  );
}

.light-filter:after {
  background: rgba(255, 255, 255, 0.64);
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.88) 0%,
    rgba(255, 255, 255, 0.45) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.88) 0%,
    rgba(255, 255, 255, 0.45) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.88) 0%,
    rgba(255, 255, 255, 0.45) 100%
  );
}

.header-filter {
  position: relative;
}

.header-filter:before,
.header-filter:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

.header-text {
  z-index: 2;
  height: 30vh;
}

/* ------------------------------------------------ */
/* Dark Header Overlay -- end  */
/* ------------------------------------------------ */

.font-weight-md {
  font-weight: 450;
}
